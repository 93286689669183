@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  * {
    /* 크롬 모바일 tap 선택영역 표시 제거 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html {
    /* 전역으로 text의 fontWeight값 설정 */
    @apply font-normal;
  }
}

body {
  @apply text-base;
}

@screen sm {
  /* // applying font size for lg breakpoint */
  body {
    @apply text-lg;
  }
}

.w-screen {
  width: 100% !important;
}

/* Carousel */

.carousel .thumbs-wrapper .thumbs {
  max-width: 440px !important;
}
.carousel .thumbs-wrapper .control-arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 30px;
  min-height: 30px;
  opacity: 1;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, 0%);
}
.carousel .thumbs-wrapper .control-arrow.control-next {
  right: calc(2% + 1px);
}
.carousel .thumbs-wrapper .control-arrow.control-prev {
  left: calc(2% + 1px);
}
.carousel .thumbs-wrapper .control-arrow::before {
  font-size: 16px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.carousel .thumbs-wrapper .control-arrow.control-next::before {
  content: '\e825';
  border: none;
}
.carousel .thumbs-wrapper .control-arrow.control-prev::before {
  content: '\e824';
  border: none;
}

.custom-right-arrow {
  position: absolute !important;
  right: 0px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  transform: rotate(-45deg);
}
.custom-right-arrow:hover {
  opacity: 1;
}
.custom-left-arrow {
  position: absolute !important;
  left: 0px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  transform: rotate(135deg);
}
.custom-left-arrow {
  opacity: 1;
}
.custom-class-select {
  position: absolute;
  right: 7%;
  top: 2px;
  margin-top: 0px !important;
}

.slick-slider {
  width: 100%;
  height: 24rem;
  overflow: hidden;
}

.slick-dots {
  bottom: 10px !important;
}

/* purgecss start ignore */
/* Markdown Styles */
/* Global */
.markdown {
  @apply text-sm leading-relaxed;
}

@screen sm {
  .markdown {
    @apply text-base;
  }
}

@screen lg {
  .markdown {
    @apply text-lg;
  }
}

/* Headers */
.markdown h1,
.markdown h2 {
  @apply my-6 text-xl font-semibold;
}
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  @apply my-3 text-lg font-semibold;
}

@screen sm {
  .markdown h1,
  .markdown h2 {
    @apply text-2xl;
  }
  .markdown h3,
  .markdown h4,
  .markdown h5,
  .markdown h6 {
    @apply text-xl;
  }
}

/* Links */
.markdown a {
  @apply text-blue-600;
}
.markdown a:hover {
  @apply underline;
}
/* Paragraph */
.markdown p {
  @apply mb-4;
}
/* Lists */
.markdown ul,
.markdown ol {
  @apply mb-4 ml-8;
}
.markdown li > p,
.markdown li > ul,
.markdown li > ol {
  @apply mb-0;
}
.markdown ol {
  @apply list-decimal;
}
.markdown ul {
  @apply list-disc;
}
/* Blockquotes */
.markdown blockquote {
  @apply p-2 mx-6 mb-4 italic bg-gray-100 border-l-4 border-gray-400;
}
.markdown blockquote > p {
  @apply mb-0;
}
/* Tables */
.markdown td,
.markdown th {
  @apply px-2 py-1 border border-gray-400;
}
.markdown tr:nth-child(odd) {
  @apply bg-gray-100;
}
.markdown table {
  @apply mb-6;
}

/* Wrap any inline highlights `that are really long`, but don't modify
   the setting for codeblocks (inside ```), which are rendered in as:
   <pre><code>...
*/
.markdown :not(pre) > code.language-text {
  white-space: pre-line;
}
/* purgecss end ignore */

/* Modal */
.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
}

.react-responsive-modal-overlay {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: transparent;
  /* box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25); */
  margin: 1.2rem 0rem 1.2rem 0rem;
  padding: 0.2rem;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-overlay-in {
  overflow-y: scroll;
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  overflow-y: scroll;
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.98);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.98);
    opacity: 0;
  }
}

/* 테이블관련 */
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

/* td:not(:last-child) {
  border-bottom: 0;
} */

/* th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
} */

@layer utilities {
  .bg-growing-underline {
    background-size: 100% 0.5em;
    background-position: 0 88%;
    @apply transition-all duration-200 bg-no-repeat;
  }
  .bg-growing-underline:hover {
    background-size: 100% 100%;
  }
  .bg-growing-underline:focus {
    background-size: 100% 100%;
  }
}

/* simple-react-calendar */
.calendar {
  border-radius: 5px;
  padding: 12px;
}
.calendar-month {
  width: 280px;
  user-select: none;
}
.calendar-month_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.calendar-week,
.calendar-days_of_week {
  display: flex;
  justify-content: space-between;
}
.calendar-week-day,
.calendar-days_of_week_day {
  display: inline-block;
  width: 40px;
  text-align: center;
  line-height: 40px;
  opacity: 0.5;
}
.calendar-week-day {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: 15px;
}
.calendar-week-day.is-selectable {
  cursor: pointer;
}
.calendar-week-day.is-disabled {
  background: #f5f5f5;
}
.calendar-week-day.is-current_month {
  opacity: 1;
}
.calendar-week-day.is-selected {
  background: #ffd43b;
}
.calendar-week-day.is-meeting {
  background: #ffd43b;
  border-radius: 99px;
  width: 40px;
  height: 40px;
}
.calendar-week-day.is-completed {
  background: #175ce6;
  border-radius: 99px;
  width: 40px;
  height: 40px;
  color: #fff;
}
.calendar-week-day.is-selected.is-start_selection {
  background: #f00;
}
.calendar-week-day.is-selected.is-end_selection {
  background: #00f;
}
.calendar-header_button.is-prev:before {
  content: '<';
}
.calendar-header_button.is-next:before {
  content: '>';
}
.date_picker {
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #e4e7ea;
  color: #303030;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  flex-basis: calendarWidth;
  max-width: calendarWidth;
  font-family: 'PT Sans', sans-serif;
}
.date_picker-month_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.date_picker-month_header_wrapper {
  text-align: center;
  margin: 0 auto 25px;
}
.date_picker-month_header_title {
  font-size: 15px;
}
.date_picker-header_button {
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
  border: 1px solid #e4e7ea;
  transition: border 0.1s ease-in, color 0.1s ease-in;
  position: relative;
  height: 35px;
  width: 35px;
}
.date_picker-header_button:focus {
  outline: 0;
}
.date_picker-header_button.is-disabled {
  cursor: default;
}
.date_picker-header_button.is-next:before,
.date_picker-header_button.is-prev:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: fill 0.1s ease-in;
  color: #303030;
}
.date_picker-header_button.is-disabled .date_picker-header_button.is-next,
.date_picker-header_button.is-disabled .date_picker-header_button.is-prev {
  color: #acb3bb;
}
.date_picker-calendar_wrapper.is-shortcut_hover_outrange .date_picker-header_button.is-next,
.date_picker-calendar_wrapper.is-shortcut_hover_outrange .date_picker-header_button.is-prev {
  border-color: #4a90e2;
  background: #e7f5ff;
}
.date_picker-header_button.is-prev:before {
  content: '<';
}
.date_picker-header_button.is-next:after {
  content: '>';
}
.date_picker-days_of_week {
  font-size: 13px;
  display: flex;
  color: #c3c3c3;
  text-align: center;
  margin-bottom: 25px;
}
.date_picker-days_of_week_day {
  flex-basis: 15%;
}
.date_picker-week {
  display: flex;
  margin-bottom: 8px;
}
.date_picker-week:last-of-type {
  margin-bottom: 0;
}
.date_picker-week-day {
  height: 34px;
  width: 34px;
  vertical-align: middle;
  font-size: 12px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 15%;
  background: #fff;
  position: relative;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
}
.date_picker-week-day:first-of-type:hover,
.date_picker-week-day:last-of-type:hover {
  border-radius: 50%;
}
.date_picker-week-day:first-of-type {
  border-radius: 50% 0 0 50%;
}
.date_picker-week-day:last-of-type {
  border-radius: 0 50% 50% 0;
}
.date_picker-week-day.is-prev_month,
.date_picker-week-day.is-next_month {
  color: rgba(172, 179, 187, 0.85);
}
.date_picker-week-day.is-today {
  font-weight: 700;
}
.date_picker-week-day.is-selected {
  background: #e3effc;
  color: #404040;
  position: relative;
  z-index: 1;
  border-radius: 0;
}
.date_picker-week-day.is-selected:first-of-type {
  border-radius: 50% 0 0 50%;
}
.date_picker-week-day.is-selected:last-of-type {
  border-radius: 0 50% 50% 0;
}
.date_picker-week-day.is-selected::before {
  background: #3863a0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  color: #fff;
}
.date_picker-calendar_wrapper.is-shortcut_hovered .date_picker-week-day.is-selected::before {
  background: #4a90e2;
}
.date_picker-week-day.is-selected:hover::before {
  content: '';
  box-shadow: inset 0 0 1px 0 #3863a0;
  background: #e3effc;
  color: #404040;
}
.date_picker-week-day.is-selected.is-start_selection.date_picker-week-day.is-selected:hover::before,
.date_picker-week-day.is-selected.is-end_selection.date_picker-week-day.is-selected:hover::before {
  background: #3863a0;
}
.date_picker-week-day.is-selected.is-start_selection,
.date_picker-week-day.is-selected.is-end_selection {
  background: #e3effc;
  color: #fff;
}
.date_picker-week-day.is-selected.is-start_selection::before,
.date_picker-week-day.is-selected.is-end_selection::before {
  content: '';
}
.date_picker-week-day.is-selected.is-start_selection {
  border-radius: 50% 0 0 50%;
}
.date_picker-week-day.is-selected.is-start_selection:last-of-type {
  border-radius: 50%;
}
.date_picker-week-day.is-selected.is-end_selection {
  border-radius: 0 50% 50% 0;
}
.date_picker-week-day.is-selected.is-end_selection.is-start_selection,
.date_picker-week-day.is-selected.is-end_selection:first-of-type {
  border-radius: 50%;
}
.date_picker-week-day.is-selectable {
  cursor: pointer;
  position: relative;
}
.date_picker-week-day.is-selectable:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.date_picker-week-day.is-selectable:hover:not(.is-selected) {
  z-index: 1;
  box-shadow: inset 0 0 1px 0 #3863a0;
}
.date_picker-week-day.is-not_selectable {
  color: #e4e7ea;
}
.date_picker-notice {
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  background: #ff6c7e;
  max-width: 238px;
}

.about-title {
  margin: 100px 0 70px;
}

.about_mt-50 {
  margin-top: 50px;
}

.about_mt-100 {
  margin-top: 100px;
}

.about_mb-50 {
  margin-bottom: 50px;
}

.about_img_2 {
  object-position: 0px 90%;
}

.about_half_img {
  width: 35%;
}

.about_half_text {
  width: 60%;
}

.about_h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  color: #000;
}

.about_sns_icon {
  width: 30px;
  height: 30px;
  background-color: #fff;
}

.about_sns_icon > img {
  width: 100%;
  height: 100%;
}

.class-enrolling-hover {
  position: absolute;
  display: none;
  top: 40px;
  left: 50px;
  background-color: #fff;
  border: 1px solid #000;
  width: 180px;
  padding: 5px;
}

.mobile-nav {
  display: none;
}

/* 모바일 햄버거메뉴 */
.custom-header-mobile-icon {
  cursor: pointer;
  width: 30px;
}

.custom-header-mobile-icon > svg {
  width: 100% !important;
}

.custom-subnav {
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100%;
  background-color: #fff;
  margin-left: 0 !important;
  transition: 0.5s;
  overflow: hidden;
}
.custom-subnav > ul {
  margin-top: 10px;
}

.custom-subnav > ul > li {
  padding: 10px;
  text-align: left;
}
.custom-subnav > ul > li > a {
  padding: 10px;
}
.custom-subnav > ul > li > a:hover {
  text-decoration: underline;
}

.custom-subnav-on {
  height: 200px;
  border-bottom: 1px solid #acb3bb;
}

.custom-subnav-on-login {
  height: 110px;
  border-bottom: 1px solid #acb3bb;
}

.custom-subnav-off {
  height: 0px;
}

.mobile-profile {
  display: none;
}

.custom_banner_2 {
  background-color: #fcd34d !important;
}
.custom_banner_2_text_pc {
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 2;
}
.custom_banner_2_text_mobile {
  display: none;
}
.custom_banner_2_text_tag {
  margin-top: 30px;
}

.custom_banner_2_img {
  position: absolute;
  top: 0;
  right: 38px;
}

.custom-class-payment {
  display: flex;
}

.custom-class-payment-img {
  margin-right: 10px;
}

@media (max-width: 640px) {
  .pc-nav {
    display: none;
  }
  .custom_banner_2_text_pc {
    display: none;
  }
  .custom_banner_2_text_mobile {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2;
    font-size: 18px;
    text-align: right;
  }
  .mobile-nav {
    display: flex;
  }
  .mobile-profile {
    display: flex;
  }
  .footer-mobile {
    display: block !important;
    width: 80%;
    margin: 0 auto;
  }
  .sns-icon-mobile {
    width: 120px;
  }
  .about-title {
    margin: 70px 0;
  }
  .about-mobile-height {
    height: auto;
  }
  .about_half_img {
    width: 100%;
    height: auto;
  }
  .about_half_text {
    width: 100%;
  }
  .about_img-half {
    display: block;
    width: 90%;
    margin: 30px auto;
    box-shadow: 2px 2px 5px 0px #c3c3c3;
  }
  .about_h3 {
    border-bottom: none;
    padding: 5px 10px;
    font-size: 18px;
  }
  .about-text-mobile {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .about-half-mobile-text {
    margin: 0;
    padding: 5px 10px;
    font-size: 14px;
  }
  .custom_banner_2_img {
    right: 0px;
  }
  .custom-mobile-text-sm {
    font-size: 0.7rem;
  }
  .px-8 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  /* cardMyClass */
  .custom-mobile-card-my-class {
    display: block !important;
    width: 90%;
    margin: 0 auto;
  }
  .custom-mobile-card-my-class-schedule-text {
    width: 5rem;
  }
  .custom-mobile-card-my-class-zoom {
    margin-left: 0 !important;
    margin-top: 5px;
  }
  .custom-mobile-card-my-class-zoom-text {
    margin-left: 18px !important;
  }

  /* classSectionSummary */
  .mobile_text_ellipsis {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 15px;
  }
  .custom-class-payment {
    display: block;
  }
  .custom-class-payment-img {
    margin-right: 0px;
    height: 12rem !important;
  }
  .custom-class-payment-date {
    display: block !important;
  }
  .custom-class-payment-date-text {
    margin-left: 0px !important;
  }
}
